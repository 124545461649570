import './ExamViewModel.css';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBRipple,
} from 'mdb-react-ui-kit';
const ExamViewModel = ({ isOpen, onClose, result }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  if (!result || !result.tabImage) {
    return null;
  }
  return (
    <div className={`nextian_modal ${isOpen ? 'show' : ''}`} onClick={onClose}>
      <div className="nextian_modal_content" onClick={handleModalClick}>
        <div className="nextian_modal_header">
          <span className="nextian_modal_close" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="card_header">
          <MDBCard>
            <MDBRipple rippleColor="light" rippleTag="div" className="bg-image hover-overlay">
              <MDBCardImage src={result.tabImage} fluid alt="..." />
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}
                ></div>
              </a>
            </MDBRipple>
            <MDBCardBody>
              <MDBCardTitle>{result.tabHeading}</MDBCardTitle>
              <div className="scroll_paragraph_1">
                <MDBCardText
                  dangerouslySetInnerHTML={{ __html: result.tabParagraph }}
                ></MDBCardText>
              </div>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
};

export default ExamViewModel;
