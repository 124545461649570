import Modal from 'react-bootstrap/Modal';
import UploadPlanNNLBenefits from './UploadPlanNNLBenefits';

const PlanNNLBenefitsModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanNNLBenefits />
      </Modal>
    </>
  );
};

export default PlanNNLBenefitsModel;
