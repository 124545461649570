import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));
function EditCPlusVideo({ editCPlusVideoData, onSubmit, onEdit }) {
  const [editVideoSnippt, setEditVideoSnippt] = useState(editCPlusVideoData || {});

  useEffect(() => {
    setEditVideoSnippt(editCPlusVideoData || {});
  }, [editCPlusVideoData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditVideoSnippt((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editCPlusVideoData) {
      onEdit(editVideoSnippt);
    } else {
      onSubmit(editVideoSnippt);
    }
  };
  return (
    <>
      <Container>
        <MDBContainer fluid>
          <MDBRow className="d-flex justify-content-center align-items-center">
            <MDBCol lg="6" className="my-1" style={{ width: '900px' }}>
              <h1 className="text-black mb-4 text-center">Edit C-Plus Video</h1>
              <form onSubmit={handleSubmit} style={{ width: '900px' }}>
                <MDBCard>
                  <MDBCardBody className="px-4">
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Video Name"
                          size="lg"
                          name="videoName"
                          value={editVideoSnippt.videoName || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Videos Link"
                          size="lg"
                          name="videoLink"
                          value={editVideoSnippt.videoLink || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBBtn className="my-4" size="lg" type="submit">
                      Update
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Container>
    </>
  );
}

export default EditCPlusVideo;
