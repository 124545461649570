import './HomeBannerView.css';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardImage } from 'mdb-react-ui-kit';

const HomeBannerView = ({ isOpen, onClose, result }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div className={`nextian_modal ${isOpen ? 'show' : ''}`} onClick={onClose}>
      <div className="nextian_modal_content" onClick={handleModalClick}>
        <div className="nextian_modal_header">
          <span className="nextian_modal_close" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="card_header">
          <MDBCard>
            <MDBCardImage src={result?.bannerImage} position="top" alt="..." />
            <MDBCardBody>
              <MDBCardTitle>{result?.bannerHeading}</MDBCardTitle>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
};

export default HomeBannerView;
