import './CourseViewModel.css';
const CourseViewModel = ({ isOpen, onClose, result }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`dashboard_faculty_modal ${isOpen ? 'show' : ''}`} onClick={onClose}>
      <div
        className="dashboard_faculty_modal_content"
        style={{ background: '#fff' }}
        onClick={handleModalClick}
      >
        <div className="dashboard_faculty_modal_header">
          <span className="dashboard_faculty_modal_close" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="dashboard_master_faculty">
          <div className="dashboard_master_faculty_content">
            <div className="dashboard_faculty_image_left">
              <img src={result?.courseImage} alt="" />
            </div>
            <div cla ssName="dashboard_faculty_description_right">
              <div className="dashboard_faculty_master_title">
                <h2>
                  Course Heading:
                  {result?.courseHeading}
                </h2>
                <p className="dashboard_faculty_degree">
                  Sub Heading:
                  {result?.subHeading}
                </p>
                <p className="dashboard_faculty_degree">
                  Sub Heading2:
                  {result?.subHeading2}
                </p>

                <h4>
                  Description:
                  <p>{result?.description}</p>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseViewModel;
