import Modal from 'react-bootstrap/Modal';
import UploadPlanZeroBanner from './UploadPlanZeroBanner';
const PlanZeroBannerModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanZeroBanner />
      </Modal>
    </>
  );
};

export default PlanZeroBannerModel;
