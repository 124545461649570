import Modal from 'react-bootstrap/Modal';
import UploadPlanUGSalient from './UploadPlanUGSalient';

const PlanUGSalientModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanUGSalient />
      </Modal>
    </>
  );
};

export default PlanUGSalientModel;
