import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFile,
} from 'mdb-react-ui-kit';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import ReactQuill from 'react-quill';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const UploadPlanTHPrice = () => {
  const [uploadExams, setUploadExams] = useState({
    planTabHeading: '',
    planTabParagraph: '',
    planTabSubHeading: '',
    planName: '',
    planValidity: '',
    planActualPrice: '',
    planCutPrice: '',
    planDescription: '',
    planTabImage: null,
  });

  const handleQuillChange = (value) => {
    setUploadExams({ ...uploadExams, planDescription: value });
  };

  const [isError, setIsError] = useState({});
  const [submittedData, setSubmittedData] = useState(null);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUploadExams({ ...uploadExams, [name]: value });
    setIsError({ ...isError, [name]: '' });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUploadExams({ ...uploadExams, planTabImage: file });
    setIsError({ ...isError, planTabImage: '' });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!uploadExams.planTabHeading.trim()) {
      newErrors.planTabHeading = 'Tab Heading is required';
      isValid = false;
    }
    setIsError(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('planTabHeading', uploadExams.planTabHeading);
      formData.append('planTabSubHeading', uploadExams.planTabSubHeading);
      formData.append('planTabParagraph', uploadExams.planTabParagraph);
      formData.append('planName', uploadExams.planName);
      formData.append('planValidity', uploadExams.planValidity);
      formData.append('planActualPrice', uploadExams.planActualPrice);
      formData.append('planCutPrice', uploadExams.planCutPrice);
      formData.append('planDescription', uploadExams.planDescription);
      formData.append('planTabImage', uploadExams.planTabImage);
      try {
        const response = await Paths.EndpointsURL.PostPlanTHTabPrice;
        const record = await axios.post(response, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setSubmittedData(record.data.data);
        toast.success(record.data.message, { position: 'top-right' });
        window.location.reload();
        setUploadExams({
          planTabHeading: '',
          planTabSubHeading: '',
          planTabParagraph: '',
          planName: '',
          planValidity: '',
          planActualPrice: '',
          planCutPrice: '',
          planDescription: '',
          planTabImage: null,
        });
        setIsError({});
      } catch (error) {
        console.error('Error uploading data:', error);
        // Handle error, show toast, etc.
      }
    }
  };

  // Function to strip HTML tags using DOMParser
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['link', 'image', 'video', 'formula', 'emoji'],
    ['undo', 'redo'],
    ['fullscreen'],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <Container>
        <MDBContainer fluid className="d-flex justify-content-center align-items-center">
          <MDBRow className="d-flex justify-content-center align-items-center">
            <MDBCol lg="8" className="my-1" style={{ width: '900px' }}>
              <h1 className="text-black mb-4 text-center">Upload Plan TH Price</h1>
              <form onSubmit={handleSubmit} style={{ width: '900px' }}>
                <MDBCard>
                  <MDBCardBody className="px-4">
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Tab Heading"
                          size="lg"
                          id="planTabHeading"
                          name="planTabHeading"
                          value={uploadExams.planTabHeading}
                          type="text"
                          onChange={handleChange}
                        />
                        {isError.planTabHeading && (
                          <span style={{ color: 'red', textAlign: 'center' }}>
                            {isError.planTabHeading}
                          </span>
                        )}
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />

                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Tab SubHeading"
                          size="lg"
                          id="planTabSubHeading"
                          name="planTabSubHeading"
                          value={uploadExams.planTabSubHeading}
                          type="text"
                          onChange={handleChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />

                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Tab Paragraph"
                          size="lg"
                          id="planTabParagraph"
                          name="planTabParagraph"
                          value={uploadExams.planTabParagraph}
                          type="text"
                          onChange={handleChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />

                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan Name"
                          size="lg"
                          id="planName"
                          name="planName"
                          value={uploadExams.planName}
                          type="text"
                          onChange={handleChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan Validity"
                          size="lg"
                          id="planValidity"
                          name="planValidity"
                          value={uploadExams.planValidity}
                          type="text"
                          onChange={handleChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan Actual Price"
                          size="lg"
                          id="planActualPrice"
                          name="planActualPrice"
                          value={uploadExams.planActualPrice}
                          type="text"
                          onChange={handleChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan CutPrice"
                          size="lg"
                          id="planCutPrice"
                          name="planCutPrice"
                          value={uploadExams.planCutPrice}
                          type="text"
                          onChange={handleChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBFile
                          size="lg"
                          id="planTabImage"
                          name="planTabImage"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <div className="small text-muted mt-2">
                          Upload your file or any other relevant file. Max file size 50 MB
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />

                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>

                      <MDBCol md="9" className="pe-5">
                        <ReactQuill
                          type=""
                          id="planDescription"
                          name="planDescription"
                          value={uploadExams.planDescription}
                          onChange={handleQuillChange}
                          modules={module}
                          theme="snow"
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBBtn className="my-4" size="lg" type="submit">
                      Submit
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Container>
    </>
  );
};

export default UploadPlanTHPrice;
