import Modal from 'react-bootstrap/Modal';
import UploadPlanNNLPrice from './UploadPlanNNLPrice';

const PlanNNLPriceModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton style={{ width: '990px' }}></Modal.Header>
        <UploadPlanNNLPrice />
      </Modal>
    </>
  );
};

export default PlanNNLPriceModel;
