import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFile,
} from 'mdb-react-ui-kit';
// import './Upload.css';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const EditOffers = ({ editOffersData, onSubmit, onEdit }) => {
  const [editAllCoursesBanner, setEditAllCoursesBanner] = useState(editOffersData || {});
  const [imagePreview, setImagePreview] = useState(editOffersData?.offersBanner || '');

  useEffect(() => {
    setEditAllCoursesBanner(editOffersData || {});
    setImagePreview(editOffersData?.offersBanner || '');
  }, [editOffersData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = files[0];
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editOffersData) {
      onEdit(editAllCoursesBanner);
    } else {
      onSubmit(editAllCoursesBanner);
    }
  };

  const handleQuillChange = (value) => {
    setEditAllCoursesBanner((prevData) => ({ ...prevData, description: value }));
  };

  // Function to strip HTML tags using DOMParser
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <Container>
        <MDBContainer fluid className="d-flex justify-content-center align-items-center">
          <MDBRow className="d-flex justify-content-center align-items-center">
            <MDBCol lg="8" className="my-1" style={{ width: '900px' }}>
              <h1 className="text-black mb-4 text-center">Edit Offers</h1>
              <form onSubmit={handleSubmit} style={{ width: '900px' }}>
                <MDBCard>
                  <MDBCardBody className="px-4">
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Validity6"
                          size="lg"
                          name="validity6"
                          value={editAllCoursesBanner.validity6}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Validity12"
                          size="lg"
                          name="validity12"
                          value={editAllCoursesBanner.validity12}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Validity24"
                          size="lg"
                          name="validity24"
                          value={editAllCoursesBanner.validity24}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Price6"
                          size="lg"
                          name="price6"
                          value={editAllCoursesBanner.price6}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Price12"
                          size="lg"
                          name="price12"
                          value={editAllCoursesBanner.price12}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Price24"
                          size="lg"
                          name="price24"
                          value={editAllCoursesBanner.price24}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBFile
                          size="lg"
                          name="offersBanner"
                          accept="image/*"
                          onChange={handleInputChange}
                        />
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Image Preview"
                            style={{ width: '100%', height: 'auto', marginTop: '10px' }}
                          />
                        )}
                        <div className="small text-muted mt-2">
                          Upload your file or any other relevant file. Max file size 50 MB
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>

                      <MDBCol md="9" className="pe-5">
                        <ReactQuill
                          type=""
                          name="description"
                          value={editAllCoursesBanner.description}
                          onChange={handleQuillChange}
                          modules={module}
                          theme="snow"
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBBtn className="my-4" size="lg" type="submit">
                      Update
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Container>
    </>
  );
};

export default EditOffers;
