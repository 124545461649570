import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Link from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Button from 'react-bootstrap/Button';
// import './Upload.css';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import Swal from 'sweetalert2';
import GenerateCouponModel from './GenerateCouponModel';
import ViewCouponCode from './ViewCouponCode';
import EditCouponModel from './EditCouponModel';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const CouponCode = () => {
  const [homeFormBanner, setHomeFormBanner] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [setIsError] = useState('');
  const [setIsDelete] = useState('');
  const [selectedCouponCode, setselectedCouponCode] = useState(null);
  const [modalShow1, setModalShow1] = useState(false);

  const handleEdit = (Coupon) => {
    setselectedCouponCode(Coupon);
    setModalShow1(true);
  };

  const showAboutBanner = async () => {
    try {
      const response = await Paths.EndpointsURL.GetCouponCode;
      const record = await axios.get(response, {
        headers: {
          'Content-type': 'application/json',
        },
      });
      return record.data.data.reverse();
    } catch (error) {
      setIsError(error.msg);
      return [];
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await confirmDelete();
    showAboutBanner();
    if (confirmed) {
      try {
        const deleteResp = Paths.EndpointsURL.DeleteCouponCode + `/${id}`;
        const res = await axios.delete(deleteResp);
        if (res.data.success && res.data.statusCode === 200) {
          handleDeleteResponse(res);
          window.location.reload();
        } else {
          toast.error(res.data.message || 'Error deleting coupon', { position: 'top-right' });
        }
      } catch (error) {
        handleError(error);
      }
    } else {
    }
  };

  const confirmDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are You Sure?',
      text: 'Are you sure that you want to delete this coupon?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    });
    return isConfirmed;
  };

  const handleDeleteResponse = (res) => {
    toast.success('Coupon deleted successfully', { position: 'top-right' });
    window.location.reload();
    setIsDelete(true);
    // console.log('Coupon deleted:', res.data);
  };

  const handleError = (error) => {
    console.error('Error deleting coupon:', error.message);
    // toast.error('Error deleting coupon', { position: 'top-right' });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [homeBannerData] = await Promise.all([showAboutBanner()]);
        setHomeFormBanner(homeBannerData);
      } catch (error) {
        setIsError(error.msg);
      }
    };

    fetchData();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [basicModal, setBasicModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const toggleOpen = () => setBasicModal(!basicModal);

  const openModal1 = (coupon) => {
    setSelectedCoupon(coupon);
    toggleOpen();
  };

  return (
    <Container>
      <PageHeader
        title="Coupon For Website"
        subTitle="Coupon For Website"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New
      </Button>
      <GenerateCouponModel show={modalShow} onHide={() => setModalShow(false)} />
      <EditCouponModel
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        editCouponData={selectedCouponCode}
      />
      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col">Plan Name</th>
            <th scope="col">Plan Validity</th>
            <th scope="col">Coupon Code</th>
            <th scope="col">Discount Amount</th>
            <th scope="col">Sales Person</th>
            <th scope="col">Total Coupon</th>
            <th scope="col">Coupon Count</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        {homeFormBanner
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((result, index) => {
            return (
              <>
                <MDBTableBody>
                  <tr>
                    <td>
                      <p className="fw-normal mb-1">{result.planName}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.planValidity}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.couponCode}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.couponAmount}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.salesPerson}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.bulkCoupon}</p>
                    </td>

                    <td>
                      <p className="fw-normal mb-1">{result.usageCount}</p>
                    </td>

                    <td>
                      <Link style={{ cursor: 'pointer' }} onClick={() => handleEdit(result)}>
                        <EditIcon />
                      </Link>
                      <Link style={{ cursor: 'pointer' }} onClick={() => openModal1(result)}>
                        <VisibilityIcon />
                      </Link>
                      <Link
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => handleDelete(result._id)}
                      >
                        <DeleteIcon />
                      </Link>
                    </td>
                  </tr>
                </MDBTableBody>
              </>
            );
          })}
        <ViewCouponCode basicModal={basicModal} toggleOpen={toggleOpen} coupon={selectedCoupon} />
      </MDBTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={homeFormBanner.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 20, 30]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default CouponCode;
