import React from 'react';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import EditCouponCode from './EditCouponCode';

const EditCouponModel = ({ editCouponData, show, onHide }) => {
  const getToken = () => {
    return localStorage.getItem('jwtToken');
  };
  const handleEditSubmit = async (editedData) => {
    try {
      const changedFields = {};
      Object.keys(editedData).forEach((key) => {
        if (editedData[key] !== editCouponData[key]) {
          changedFields[key] = editedData[key];
        }
      });
      if (Object.keys(changedFields).length === 0) {
        toast.info('No changes were made to the coupon.', { position: 'top-right' });
        return;
      }
      const response = await axios.put(
        Paths.EndpointsURL.EditCouponCode + editCouponData._id,
        changedFields,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (response.data.success) {
        toast.success('Coupon Updated Successfully', { position: 'top-right' });
        window.location.reload();
        onHide();
      } else {
        throw new Error(response.data.message || 'Failed to update coupon');
      }
    } catch (error) {
      let errorMessage = 'Error updating coupon: ';
      if (error.response) {
        errorMessage += `Server responded with status ${error.response.status}. `;
        errorMessage += error.response.data.message || JSON.stringify(error.response.data);
      } else if (error.request) {
        errorMessage += 'No response received from server.';
      } else {
        errorMessage += error.message;
      }
      console.error('Full error object:', error);
      toast.error(errorMessage);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <EditCouponCode editCouponData={editCouponData} onEdit={handleEditSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default EditCouponModel;
