import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Link from '@mui/material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Button from 'react-bootstrap/Button';
import EditIcon from '@mui/icons-material/Edit';
import './Upload.css';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import Swal from 'sweetalert2';
import AllCourseBannerModal from './AllCourseBannerModel';
import EditAllCoursesBannerModel from './EditAllCourseBannerModal';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const AllCourseBanner = () => {
  const [homeFormBanner, setHomeFormBanner] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isError, setIsError] = useState('');

  const handleEdit = (id) => {
    setSelectedBanner(id);
    setModalShow1(true);
  };

  const showAboutBanner = async () => {
    try {
      const response = await Paths.EndpointsURL.AllCoursesBanner;
      const record = await axios.get(response, {
        headers: {
          'Content-type': 'application/json',
        },
      });
      return record.data.data;
    } catch (error) {
      setIsError(error.msg);
      return [];
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmed.isConfirmed) {
      try {
        await axios.delete(Paths.EndpointsURL.DeleteCoursesBanner + id);
        setHomeFormBanner(homeFormBanner.filter((banner) => banner._id !== id));
        showAboutBanner();
        toast.success('Banner deleted successfully', { position: 'top-right' });
      } catch (error) {
        toast.error('Error deleting banner: ' + error.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [homeBannerData] = await Promise.all([showAboutBanner()]);
        setHomeFormBanner(homeBannerData);
      } catch (error) {
        setIsError(error.msg);
      }
    };

    fetchData();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
      <PageHeader
        title="All Courses Banner"
        subTitle="Single All Banner"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New
      </Button>

      <AllCourseBannerModal show={modalShow} onHide={() => setModalShow(false)} />
      <EditAllCoursesBannerModel
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        editAllCoursesBannerData={selectedBanner}
      />

      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col">Banner Heading</th>
            <th scope="col">Banner SubHeading</th>
            <th scope="col">Banner Image</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        {homeFormBanner
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((result, index) => {
            return (
              <>
                <MDBTableBody>
                  <tr>
                    <td>
                      <p className="fw-normal mb-1">{result.bannerHeading}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.bannerSubHeading}</p>
                    </td>
                    <td>
                      <img
                        src={result.bannerImage}
                        alt=""
                        style={{ width: '45px', height: '45px' }}
                        className="rounded-circle"
                      />
                    </td>

                    <td>
                      <Link style={{ cursor: 'pointer' }} onClick={() => handleEdit(result)}>
                        <EditIcon />
                      </Link>
                      <Link>
                        <VisibilityIcon />
                      </Link>

                      <Link
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => handleDelete(result._id)}
                      >
                        <DeleteIcon />
                      </Link>
                    </td>
                  </tr>
                </MDBTableBody>
              </>
            );
          })}
      </MDBTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={homeFormBanner.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default AllCourseBanner;
