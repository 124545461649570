export const navigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { label: 'Components', type: 'label' },
  {
    name: 'Components',
    icon: 'favorite',
    children: [
      { name: 'Home Course', path: '/home/course', iconText: 'E' },
      { name: 'Faculty Details', path: '/faculty', iconText: 'F' },
      { name: 'Display Nextians', path: '/display/nextians', iconText: 'D' },
      { name: 'Success Stories', path: '/success/stories', iconText: 'I' },
      { name: 'Blogs', path: '/display/blog', iconText: 'M' },
      { name: 'Recommended Videos', path: '/video/snippts', iconText: 'R' },
      { name: 'Vacancy Videos', path: '/vacancy-video', iconText: 'V' },
      { name: 'All FAQ', path: '/all/faq', iconText: 'S' },
      { name: 'Home FAQ', path: '/home/faq', iconText: 'S' },
      { name: 'Plan Caters', path: '/plan-caters', iconText: 'S' },
      { name: 'Offers', path: '/offers', iconText: 'S' },
      { name: 'Exams', path: '/all/exams', iconText: 'S' },
      { name: 'Why Choose Us', path: '/why/choose', iconText: 'T' },
      { name: 'Razorpay Payment', path: '/razorpay/payment', iconText: 'T' },
      { name: 'Career Page', path: '/career', iconText: 'T' },
      { name: 'Coupon Code', path: '/coupon-code', iconText: 'U' },
    ],
  },

  { label: 'Banner', type: 'label' },
  {
    name: 'Website Banner',
    icon: 'books',
    children: [
      { name: 'Home Banner', path: '/home/banner', iconText: 'A' },
      { name: 'About Banner', path: '/about/banner/', iconText: 'B' },
      { name: 'Offers Banner', path: '/offers/banner', iconText: 'C' },
      { name: 'All Courses Banner', path: 'all/course/banner', iconText: 'D' },
      { name: 'All Exams Banner', path: 'exams/banner', iconText: 'E' },
      { name: 'Succss Stories Banner', path: 'stories/banner/', iconText: 'E' },
      { name: 'News Room Banner', path: 'newsroom/banner/', iconText: 'F' },
      { name: 'Blogs Banner', path: 'blogs/banner/', iconText: 'G' },
      { name: 'Faculty Banner', path: 'faculty/banner/', iconText: 'H' },
      { name: 'Nextians Banner', path: 'nextians/banner/', iconText: 'I' },
      { name: 'NORCET Banner', path: 'norcet/banner/', iconText: 'J' },
      { name: 'Nursing Officer Banner', path: 'nursing-officer/banner/', iconText: 'K' },
      { name: 'Exam MSc Banner', path: 'exam-msc/banner/', iconText: 'L' },
      { name: 'Exam NCLEX Banner', path: 'exam-nclex/banner/', iconText: 'M' },
      { name: 'CHO Banner', path: 'cho/banner/', iconText: 'M' },
    ],
  },

  { label: 'Students Form', type: 'label' },
  {
    name: 'Students Query',
    icon: 'books',
    children: [
      { name: 'Home Form', path: '/query/form', iconText: 'A' },
      { name: 'Offers Form', path: '/offers/form', iconText: 'D' },
      { name: 'Plan Zero', path: '/plan-zero/form', iconText: 'A' },
      { name: 'MLB Pro', path: '/mlb-pro/form', iconText: 'A' },
      { name: 'Plan CPlus', path: '/c-plus/form', iconText: 'A' },
      { name: 'Plan UG', path: '/plan-ug/form', iconText: 'A' },
      { name: 'MLB Lite', path: '/mlb-lite/form', iconText: 'A' },
      { name: 'Plan A', path: '/plan-a/form', iconText: 'A' },
      { name: 'Plan MSc', path: '/plan-msc/form', iconText: 'A' },
      { name: 'Plan NNL', path: '/plan-nnl/form', iconText: 'A' },
      { name: 'Plan TH', path: '/plan-th/form', iconText: 'A' },
      { name: 'Share Feedback', path: '/home/feedback', iconText: 'B' },
      { name: 'ContactUs Form', path: '/contactus/form', iconText: 'C' },
      { name: 'Hiring Form', path: '/hiring/form', iconText: 'C' },
      { name: 'Help&Support Form', path: '/help/support', iconText: 'C' },
      { name: 'About Feedback', path: '/about/feedback', iconText: 'E' },
    ],
  },

  { label: 'All Plans Video', type: 'label' },
  {
    name: 'Plan Video',
    icon: 'books',
    children: [
      { name: 'Zero Video', path: '/plan-zero/video', iconText: 'F' },
      { name: 'MLB PRO Video', path: '/mlb-pro/video', iconText: 'F' },
      { name: 'C Plus Video', path: '/c-plus/video', iconText: 'F' },
      { name: 'Plan UG Video', path: '/plan-ug/video', iconText: 'F' },
      { name: 'Plan MLB Lite Video', path: '/mlb-lite/video', iconText: 'F' },
      { name: 'Plan A Video', path: '/plan-a/video', iconText: 'F' },
      { name: 'Plan MSc Video', path: '/plan-msc/video', iconText: 'F' },
      { name: 'Plan NNL Video', path: '/plan-nnl/video', iconText: 'F' },
      { name: 'Plan TH Video', path: '/plan-th/video', iconText: 'F' },
    ],
  },

  { label: 'All Plans', type: 'label' },
  {
    name: 'Plan Zero',
    icon: 'books',
    children: [
      { name: 'Plan Price', path: '/price', iconText: 'F' },
      { name: 'Key Benefits', path: '/key-benefits', iconText: 'F' },
      { name: 'Salient', path: '/plan-zero/salient', iconText: 'F' },
      { name: 'Zero Banner', path: '/plan-zero/banner', iconText: 'N' },
    ],
  },

  {
    name: 'MLB PRO',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/mlb-pro/benefits', iconText: 'E' },
      { name: 'Salient', path: '/mlb-pro/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/mlb-pro/price', iconText: 'F' },
      { name: 'MLB Pro Banner', path: '/mlb-pro/banner', iconText: 'O' },
    ],
  },

  {
    name: 'MLB Lite',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/mlb-lite/benefits', iconText: 'E' },
      { name: 'Salient', path: '/mlb-lite/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/mlb-lite/price', iconText: 'F' },
      { name: 'MLB Lite Banner', path: '/mlb-lite/banner', iconText: 'F' },
    ],
  },

  {
    name: 'Plan C+',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/c-plus/benefits', iconText: 'E' },
      { name: 'Salient', path: '/c-plus/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/c-plus/price', iconText: 'F' },
      { name: 'C-Plus Banner', path: '/c-plus/banner', iconText: 'P' },
    ],
  },

  {
    name: 'Plan UG',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/plan-ug/benefits', iconText: 'E' },
      { name: 'Salient', path: '/plan-ug/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/plan-ug/price', iconText: 'F' },
      { name: 'UG Banner', path: '/plan-ug/banner', iconText: 'F' },
    ],
  },

  {
    name: 'Plan A',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/plan-a/benefits', iconText: 'E' },
      { name: 'Salient', path: '/plan-a/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/plan-a/price', iconText: 'F' },
      { name: 'PlanA Banner', path: '/plan-a/banner', iconText: 'F' },
    ],
  },

  {
    name: 'Plan MSC',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/plan-msc/benefits', iconText: 'E' },
      { name: 'Salient', path: '/plan-msc/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/plan-msc/price', iconText: 'F' },
      { name: 'MSc Banner', path: '/plan-msc/banner', iconText: 'F' },
    ],
  },

  {
    name: 'Plan NNL',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/nnl-vsl/benefits', iconText: 'E' },
      { name: 'Salient', path: '/nnl-vsl/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/nnl-vsl/price', iconText: 'F' },
      { name: 'NNL-VSL Banner', path: '/nnl-vsl/banner', iconText: 'F' },
    ],
  },

  {
    name: 'Plan TH',
    icon: 'books',
    children: [
      { name: 'Key Benefits', path: '/plan-th/benefits', iconText: 'E' },
      { name: 'Salient', path: '/plan-th/salient', iconText: 'F' },
      { name: 'Plan Price', path: '/plan-th/price', iconText: 'F' },
      { name: 'TH Banner', path: '/plan-th/banner', iconText: 'F' },
    ],
  },
];
