import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { Paths } from '../../../baseApi/baseApi';
import axios from 'axios';
import EditPlanUGBanner from './EditPlanUGBanner';
const EditPlanUGBannerModel = ({ editPlanUGBannerData, show, onHide }) => {
  const handleEditSubmit = async (editedData) => {
    //console.log('Banner Data:', editedData);
    try {
      const formData = new FormData();
      Object.keys(editedData).forEach((key) => {
        formData.append(key, editedData[key]);
      });
      const response = await axios.put(
        Paths.EndpointsURL.EditPlanUGBanner + editedData._id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success(response.data.message, { position: 'top-right' });
      window.location.reload();
      onHide();
    } catch (error) {
      console.error('Error updating banner:', error);
      toast.error('Error updating banner: ' + error.message);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <EditPlanUGBanner editPlanUGBannerData={editPlanUGBannerData} onEdit={handleEditSubmit} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPlanUGBannerModel;
