import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const EditPlanCaters = ({ editAllFAQData, onSubmit, onEdit }) => {
  const [editAllCoursesBanner, setEditAllCoursesBanner] = useState(editAllFAQData || {});

  useEffect(() => {
    setEditAllCoursesBanner(editAllFAQData || {});
  }, [editAllFAQData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditAllCoursesBanner((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleQuillChange = (value) => {
    setEditAllCoursesBanner((prevData) => ({ ...prevData, catersParagraph: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editAllFAQData) {
      onEdit(editAllCoursesBanner);
    } else {
      onSubmit(editAllCoursesBanner);
    }
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <Container>
      <MDBContainer fluid className="d-flex justify-content-center align-items-center">
        <MDBRow className="d-flex justify-content-center align-items-center">
          <MDBCol lg="8" className="my-1" style={{ width: '900px' }}>
            <h1 className="text-black mb-4 text-center">Edit Caters</h1>
            <form onSubmit={handleSubmit} style={{ width: '900px' }}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5"></MDBCol>
                    <MDBCol md="9" className="pe-5">
                      <MDBInput
                        label="Caters Heading"
                        size="lg"
                        name="catersHeading"
                        value={editAllCoursesBanner.catersHeading || ''}
                        type="text"
                        onChange={handleInputChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n3" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5"></MDBCol>
                    <MDBCol md="9" className="pe-5">
                      <ReactQuill
                        value={editAllCoursesBanner.catersParagraph || ''}
                        onChange={handleQuillChange}
                        modules={modules}
                      />
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n3" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Update
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
};

export default EditPlanCaters;
