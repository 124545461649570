import Modal from 'react-bootstrap/Modal';
import UploadPlanUGBanner from './UploadPlanUGBanner';
const PlanUGBannerModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanUGBanner />
      </Modal>
    </>
  );
};

export default PlanUGBannerModel;
