import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFile,
} from 'mdb-react-ui-kit';
import { useState, useEffect } from 'react';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const EditSuccessStoriesBanner = ({ editStoriesBannerData, onSubmit, onEdit }) => {
  const [editAllCoursesBanner, setEditAllCoursesBanner] = useState(editStoriesBannerData || {});
  const [imagePreview, setImagePreview] = useState(editStoriesBannerData?.bannerImage || '');

  useEffect(() => {
    setEditAllCoursesBanner(editStoriesBannerData || {});
    setImagePreview(editStoriesBannerData?.bannerImage || '');
  }, [editStoriesBannerData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = files[0];
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editStoriesBannerData) {
      onEdit(editAllCoursesBanner);
    } else {
      onSubmit(editAllCoursesBanner);
    }
  };

  return (
    <>
      <Container>
        <MDBContainer fluid>
          <MDBRow
            className="d-flex justify-content-center align-items-center"
            style={{ width: '900px' }}
          >
            <MDBCol lg="6" className="my-1" style={{ width: '125%' }}>
              <h1 className="text-black mb-4 text-center">Edit Success Stories Banner</h1>
              <form onSubmit={handleSubmit}>
                <MDBCard>
                  <MDBCardBody className="px-4">
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Banner Heading"
                          size="lg"
                          name="bannerHeading"
                          value={editAllCoursesBanner.bannerHeading || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Banner Name"
                          size="lg"
                          name="bannerName"
                          value={editAllCoursesBanner.bannerName || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBFile
                          name="bannerImage"
                          type="file"
                          size="lg"
                          accept="image/*"
                          onChange={handleInputChange}
                        />
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Image Preview"
                            style={{ width: '100%', height: 'auto', marginTop: '10px' }}
                          />
                        )}
                        <div className="small text-muted mt-2">
                          Upload your file or any other relevant file. Max file size 50 MB
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBBtn className="my-4" size="lg" type="submit">
                      Update
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Container>
    </>
  );
};

export default EditSuccessStoriesBanner;
