import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Link from '@mui/material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Button from 'react-bootstrap/Button';
import EditIcon from '@mui/icons-material/Edit';
import './Upload.css';
import axios from 'axios';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Paths } from '../../../../app/baseApi/baseApi';
import UploadBannerModal from './UploadBannerModel';
import EditBannerModel from './EditBannerModel';
import HomeBannerView from './HomeBannerView';
import './HomeBannerView.css';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const HomeBanner = () => {
  const [homeFormBanner, setHomeFormBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedBanner1, setSelectedBanner1] = useState(null);
  const [selectedFaculty, setSelectedFaculty] = useState({});
  const [isError, setIsError] = useState('');

  const handleEdit = (id) => {
    setSelectedBanner(id);
    setModalShow1(true);
  };

  const showHomeBanner = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(Paths.EndpointsURL.HomeFormBanner, {
        headers: {
          'Content-type': 'application/json',
        },
      });
      setIsLoading(false);
      return response.data || [];
    } catch (error) {
      console.error('Error fetching banners:', error);
      setIsError(
        error.response?.data?.message ||
          error.message ||
          'An error occurred while fetching banner data'
      );
      setIsLoading(false);
      return [];
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmed.isConfirmed) {
      try {
        await axios.delete(Paths.EndpointsURL.DeleteHomeBanner + id);
        setHomeFormBanner(homeFormBanner.filter((banner) => banner._id !== id));
        toast.success('Banner deleted successfully', { position: 'top-right' });
      } catch (error) {
        toast.error('Error deleting banner: ' + error.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const homeBannerData = await showHomeBanner();
        setHomeFormBanner(homeBannerData || []);
      } catch (error) {
        setIsError(error.message || 'An error occurred');
        setHomeFormBanner([]);
      }
    };

    fetchData();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openModal = (result) => {
    setSelectedFaculty(result);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (isLoading) {
    return <div>Loading banners...</div>;
  }

  if (isError) {
    return <div>Error: {isError}</div>;
  }
  return (
    <Container>
      <PageHeader
        title="Home Banner"
        subTitle="Home All Banner"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New
      </Button>
      <HomeBannerView
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        editExamsData={selectedBanner1}
      />
      <EditBannerModel
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        editHomeBannerData={selectedBanner}
      />
      <UploadBannerModal show={modalShow} onHide={() => setModalShow(false)} />
      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Banner Name</th>
            <th scope="col">Banner Image</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        {homeFormBanner
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((result, index) => {
            return (
              <>
                <MDBTableBody>
                  <tr>
                    <td>
                      <p className="fw-normal mb-1">{result.serialNumber}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.bannerHeading}</p>
                    </td>
                    <td>
                      <img
                        src={result.bannerImage}
                        alt=""
                        style={{ width: '45px', height: '45px' }}
                        className="rounded-circle"
                      />
                    </td>

                    <td>
                      <Link style={{ cursor: 'pointer' }} onClick={() => handleEdit(result)}>
                        <EditIcon />
                      </Link>
                      <Link
                        style={{ cursor: 'pointer', color: 'green' }}
                        onClick={() => openModal(result)}
                      >
                        <HomeBannerView />
                        <VisibilityIcon />
                      </Link>

                      <Link
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => handleDelete(result._id)}
                      >
                        <DeleteIcon />
                      </Link>
                    </td>
                  </tr>
                  <HomeBannerView
                    isOpen={modalOpen}
                    onClose={closeModal}
                    result={selectedFaculty}
                  />
                </MDBTableBody>
              </>
            );
          })}
      </MDBTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={homeFormBanner.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default HomeBanner;
