import Modal from 'react-bootstrap/Modal';
import './Upload.css';
import UploadHomeCourses from './UploadHomeCourses';
const HomeCoursesModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadHomeCourses />
      </Modal>
    </>
  );
};

export default HomeCoursesModel;
