import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFile,
} from 'mdb-react-ui-kit';
import ReactQuill from 'react-quill';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const EditPlanAPrice = ({ editPlanAPriceData, onSubmit, onEdit }) => {
  const [editAllCoursesBanner, setEditAllCoursesBanner] = useState({});
  const [imagePreview, setImagePreview] = useState('');

  useEffect(() => {
    if (editPlanAPriceData) {
      const filteredData = Object.fromEntries(
        Object.entries(editPlanAPriceData)
          .filter(([key]) => !['createdAt', 'updatedAt', 'id', '__v'].includes(key))
          .map(([key, value]) => [key, value === null ? '' : value])
      );
      setEditAllCoursesBanner(filteredData);
      setImagePreview(editPlanAPriceData.planTabImage || '');
    }
  }, [editPlanAPriceData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = files[0];
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = Object.fromEntries(
      Object.entries(editAllCoursesBanner).map(([key, value]) => {
        return [key, value];
      })
    );
    if (editPlanAPriceData) {
      onEdit(updatedData);
    } else {
      onSubmit(updatedData);
    }
  };

  const handleQuillChange = (value) => {
    setEditAllCoursesBanner((prevData) => ({ ...prevData, planDetails: value }));
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="6" className="my-1" style={{ width: '125%' }}>
            <h1 className="text-black mb-4 text-center">Edit PlanA Price</h1>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  {Object.entries(editAllCoursesBanner).map(([key, value]) => {
                    if (
                      [
                        'planTabImage',
                        'planDetails',
                        'createdAt',
                        'updatedAt',
                        'id',
                        '__v',
                      ].includes(key)
                    )
                      return null;

                    return (
                      <React.Fragment key={key}>
                        <MDBRow className="align-items-center pt-4 pb-3">
                          <MDBCol md="3" className="ps-5"></MDBCol>
                          <MDBCol md="9" className="pe-5">
                            <MDBInput
                              label={
                                key.charAt(0).toUpperCase() +
                                key.slice(1).replace(/([A-Z])/g, ' $1')
                              }
                              size="lg"
                              name={key}
                              value={value ?? ''}
                              type="text"
                              onChange={handleInputChange}
                            />
                          </MDBCol>
                        </MDBRow>
                        <hr className="mx-n3" />
                      </React.Fragment>
                    );
                  })}

                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5"></MDBCol>
                    <MDBCol md="9" className="pe-5">
                      <ReactQuill
                        value={editAllCoursesBanner.planDetails || ''}
                        onChange={handleQuillChange}
                        modules={module}
                        theme="snow"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n3" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5"></MDBCol>
                    <MDBCol md="9" className="pe-5">
                      <MDBFile
                        name="planTabImage"
                        type="file"
                        size="lg"
                        accept="image/*"
                        onChange={handleInputChange}
                      />
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Image Preview"
                          style={{ width: '100%', height: 'auto', marginTop: '10px' }}
                        />
                      )}
                      <div className="small text-muted mt-2">
                        Upload your file or any other relevant file. Max file size 50 MB
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n3" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Update
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
};

export default EditPlanAPrice;
