import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import { useEffect, useState } from 'react';

export default function GenerateCoupon() {
  const [generateCoupon, setGenerateCoupon] = useState({
    couponAmount: '',
    couponCode: '',
    salesPerson: '',
    maximumDiscount: '',
    minimumPurchase: '',
    bulkCoupon: '',
    couponType: '',
    planName: '',
    planValidity: '',
    startDate: '',
    endDate: '',
  });

  const [isError, setIsError] = useState({});
  const [plans, setPlans] = useState([]);

  const showAllPlan = async () => {
    try {
      const MLBPROPriceApi = Paths.EndpointsURL.MLBPROPriceTab;
      const CPlusPriceApi = Paths.EndpointsURL.PlanCPlusTabPrice;
      const PlanUGTabPriceApi = Paths.EndpointsURL.PlanUGTabPrice;
      const PlanATabPriceApi = Paths.EndpointsURL.PlanATabPrice;
      const PlanMScTabPriceApi = Paths.EndpointsURL.PlanMScTabPrice;
      const PlanTHTabPriceApi = Paths.EndpointsURL.PlanTHTabPrice;
      const [
        MLBPROPriceApiResponse,
        CPlusPriceApiResponse,
        PlanUGTabPriceResponse,
        PlanATabPriceResponse,
        PlanMScTabPriceResponse,
        PlanTHTabPriceResponse,
      ] = await Promise.all([
        axios.get(MLBPROPriceApi, {
          headers: { 'Content-type': 'application/json' },
        }),
        axios.get(CPlusPriceApi, {
          headers: { 'Content-type': 'application/json' },
        }),
        axios.get(PlanUGTabPriceApi, {
          headers: { 'Content-type': 'application/json' },
        }),
        axios.get(PlanATabPriceApi, {
          headers: { 'Content-type': 'application/json' },
        }),
        axios.get(PlanMScTabPriceApi, {
          headers: { 'Content-type': 'application/json' },
        }),
        axios.get(PlanTHTabPriceApi, {
          headers: { 'Content-type': 'application/json' },
        }),
      ]);
      const MLBPROPriceData = MLBPROPriceApiResponse.data.data;
      const CPlusPriceData = CPlusPriceApiResponse.data.data;
      const PlanUGTabPriceData = PlanUGTabPriceResponse.data.data;
      const PlanATabPriceData = PlanATabPriceResponse.data.data;
      const PlanMScTabPriceData = PlanMScTabPriceResponse.data.data;
      const PlanTHTabPriceData = PlanTHTabPriceResponse.data.data;
      const allPlans = [
        ...MLBPROPriceData,
        ...CPlusPriceData,
        ...PlanUGTabPriceData,
        ...PlanATabPriceData,
        ...PlanMScTabPriceData,
        ...PlanTHTabPriceData,
      ];
      setPlans(allPlans);
    } catch (error) {
      setIsError(error.message);
    }
  };

  const handlePlanChange = (event) => {
    const selectedPlanName = event.target.value;
    const selectedPlan = plans.find((plan) => plan.planName === selectedPlanName);
    if (selectedPlan) {
      setGenerateCoupon({
        ...generateCoupon,
        planName: selectedPlan.planName,
        planValidity: selectedPlan.planValidity,
      });
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setGenerateCoupon({ ...generateCoupon, [name]: value });
    setIsError({ ...isError, [name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!generateCoupon.couponCode.trim()) {
      newErrors.couponCode = 'Coupon code is required';
      isValid = false;
    }
    if (!generateCoupon.planName.trim()) {
      newErrors.planName = 'Plan name is required';
      isValid = false;
    }
    if (!generateCoupon.planValidity.trim()) {
      newErrors.planValidity = 'Plan validity is required';
      isValid = false;
    }
    if (!generateCoupon.startDate.trim()) {
      newErrors.startDate = 'Start date is required';
      isValid = false;
    }
    if (!generateCoupon.endDate.trim()) {
      newErrors.endDate = 'End date is required';
      isValid = false;
    }

    setIsError(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newGenerateCoupon = { ...generateCoupon, id: new Date() };
    if (validateForm()) {
      try {
        const response = await Paths.EndpointsURL.GenerateCouponCode;
        const token = localStorage.getItem('jwtToken');
        const res = await axios({
          url: response,
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data: JSON.stringify(newGenerateCoupon),
        });

        setGenerateCoupon(res.data.data);
        toast.success(res.data.message, { position: 'top-right' });
        window.location.reload();
      } catch (error) {
        console.error('Error generating coupon:', error);
        toast.error('Coupon generation failed!', { position: 'top-right' });
      }
    }
  };

  useEffect(() => {
    showAllPlan();
  }, []);
  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Generate Coupon</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <select
                        className="form-control"
                        id="planName"
                        name="planName"
                        onChange={handlePlanChange}
                        value={generateCoupon.planName}
                        size="lg"
                      >
                        <option value="">Select Plan Name</option>
                        {plans.length > 0 ? (
                          plans.map((plan, index) => (
                            <option key={index} value={plan.planName}>
                              {plan.planName}
                            </option>
                          ))
                        ) : (
                          <option disabled>Loading Plans...</option>
                        )}
                      </select>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Validity"
                        name="planValidity"
                        id="planValidity"
                        onChange={handleChange}
                        value={generateCoupon.planValidity}
                        size="lg"
                        type="text"
                      />
                      {isError.planValidity && (
                        <div className="text-danger">{isError.planValidity}</div>
                      )}
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Coupon Code and Amount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Coupon Code"
                        name="couponCode"
                        id="couponCode"
                        onChange={handleChange}
                        value={generateCoupon.couponCode}
                        size="lg"
                        type="text"
                      />
                      {isError.couponCode && (
                        <div className="text-danger">{isError.couponCode}</div>
                      )}
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Coupon Amount"
                        name="couponAmount"
                        id="couponAmount"
                        onChange={handleChange}
                        value={generateCoupon.couponAmount}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Minimum Purchase"
                        name="minimumPurchase"
                        id="minimumPurchase"
                        onChange={handleChange}
                        value={generateCoupon.minimumPurchase}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Maximum Discount"
                        name="maximumDiscount"
                        id="maximumDiscount"
                        onChange={handleChange}
                        value={generateCoupon.maximumDiscount}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Sales Person"
                        name="salesPerson"
                        id="salesPerson"
                        onChange={handleChange}
                        value={generateCoupon.salesPerson}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Total Coupon"
                        name="bulkCoupon"
                        id="bulkCoupon"
                        onChange={handleChange}
                        value={generateCoupon.bulkCoupon}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <select
                        className="form-control"
                        id="couponType"
                        name="couponType"
                        onChange={handleChange}
                        value={generateCoupon.couponType}
                        size="lg"
                      >
                        <option value="">Select Coupon Type</option>
                        <option value="Sales">Sales</option>
                        <option value="Marketing">Marketing</option>
                        <option value="video">video</option>
                      </select>
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n2 m-0" />
                  {/* Start Date and End Date */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Start Date"
                        id="startDate"
                        name="startDate"
                        onChange={handleChange}
                        value={generateCoupon.startDate}
                        size="lg"
                        type="date"
                      />
                      {isError.startDate && <div className="text-danger">{isError.startDate}</div>}
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="End Date"
                        id="endDate"
                        name="endDate"
                        onChange={handleChange}
                        value={generateCoupon.endDate}
                        size="lg"
                        type="date"
                      />
                      {isError.endDate && <div className="text-danger">{isError.endDate}</div>}
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Submit
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
