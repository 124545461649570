import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { TablePagination } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import PageHeader from 'app/PageHeader';
import OffersBannerModal from './OffersBannerModel';
import { Paths } from '../../../../app/baseApi/baseApi';
import Link from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Button from 'react-bootstrap/Button';
import EditOffersBannerModel from './EditOffersBannerModel';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const OffersBanner = () => {
  const [banners, setBanners] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);

  const handleEdit = (id) => {
    setSelectedBanner(id);
    setModalShow1(true);
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(Paths.EndpointsURL.OffersBanner, {
          headers: { 'Content-Type': 'application/json' },
        });
        setBanners(response.data.data);
      } catch (error) {
        toast.error('Failed to fetch banners: ' + error.message);
      }
    };

    fetchBanners();
  }, []);

  const handleDelete = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmed.isConfirmed) {
      try {
        await axios.delete(Paths.EndpointsURL.DeleteOffersBanner + id);
        setBanners(banners.filter((banner) => banner._id !== id));
        toast.success('Banner deleted successfully', { position: 'top-right' });
      } catch (error) {
        toast.error('Error deleting banner: ' + error.message);
      }
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newBanners = Array.from(banners);
    const [reorderedBanner] = newBanners.splice(result.source.index, 1);
    newBanners.splice(result.destination.index, 0, reorderedBanner);
    setBanners(newBanners);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  return (
    <Container>
      <PageHeader
        title="Offers Banner"
        subTitle="Manage your offers banners"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New
      </Button>
      <OffersBannerModal show={modalShow} onHide={() => setModalShow(false)} />
      <EditOffersBannerModel
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        editOffersBannerData={selectedBanner}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-banners">
          {(provided) => (
            <MDBTable align="middle" {...provided.droppableProps} ref={provided.innerRef}>
              <MDBTableHead>
                <tr>
                  <th>S.No</th>
                  <th>Banner Name</th>
                  <th>Banner Image</th>
                  <th>Actions</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {banners
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((banner, index) => (
                    <Draggable key={banner._id} draggableId={banner._id} index={index}>
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <td>{banner.serialNumber}</td>
                          <td>{banner.bannerName}</td>
                          <td>
                            <img
                              src={banner.bannerImages}
                              alt=""
                              style={{ width: '45px', height: '45px' }}
                            />
                          </td>
                          <td>
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleEdit(banner)}>
                              <EditIcon />
                            </Link>
                            <Link>
                              <VisibilityIcon />
                            </Link>
                            <Link
                              style={{ color: 'red', cursor: 'pointer' }}
                              onClick={() => handleDelete(banner._id)}
                            >
                              <DeleteIcon />
                            </Link>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </MDBTableBody>
            </MDBTable>
          )}
        </Droppable>
      </DragDropContext>
      <TablePagination
        component="div"
        count={banners.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        rowsPerPageOptions={[5, 10, 15]}
      />
    </Container>
  );
};

export default OffersBanner;
