import './CareerViewModel.css';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardLink } from 'mdb-react-ui-kit';

const CareerViewModel = ({ isOpen, onClose, result }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div className={`nextian_modal ${isOpen ? 'show' : ''}`} onClick={onClose}>
      <div className="nextian_modal_content" onClick={handleModalClick}>
        <div className="nextian_modal_header">
          <span className="nextian_modal_close" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="card_header">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Name: {result?.name}</MDBCardTitle>
              <MDBCardTitle>Phone: {result?.phone}</MDBCardTitle>
              <MDBCardTitle>Email: {result?.email}</MDBCardTitle>
              <MDBCardTitle>Address: {result?.address}</MDBCardTitle>
              <MDBCardTitle>Current Employer: {result?.currentEmployer}</MDBCardTitle>
              <MDBCardTitle>Total Experience: {result?.totalExperience}</MDBCardTitle>
              <MDBCardTitle>Immediate Joiner: {result?.immediateJoiner}</MDBCardTitle>
              <MDBCardTitle>Qualification: {result?.qualification}</MDBCardTitle>
              <MDBCardTitle>CollegeName: {result?.collegeName}</MDBCardTitle>
              <MDBCardTitle>Graudtion Year: {result?.passingYear}</MDBCardTitle>
              <MDBCardTitle>ApplyingFor: {result?.ApplyingFor}</MDBCardTitle>
              <MDBCardTitle>
                <MDBCardLink href={result?.resume} target="_blank">
                  View Resume
                </MDBCardLink>
              </MDBCardTitle>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
};

export default CareerViewModel;
