import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import PageHeader from 'app/PageHeader';
import Button from 'react-bootstrap/Button';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import Swal from 'sweetalert2';
import NewsRoomBannerModel from './NewsRoomBannerModel';
import EditNewsRoomBannerModel from './EditEditNewsRoomBannerModel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const NewsRoomBanner = () => {
  const [mastermindDetails, setMasterMindDetails] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isError, setIsError] = useState('');
  const [isDelete, setIsDelete] = useState('');

  const handleEdit = (id) => {
    setSelectedBanner(id);
    setModalShow1(true);
  };

  const showMastermind = async () => {
    try {
      const response = await Paths.EndpointsURL.NewsRoomBanner;
      axios({
        url: response,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }).then((record) => {
        setMasterMindDetails(record.data.data);
        //console.log(record.data.data);
      });
    } catch (error) {
      setIsError(error.msg);
      console.log(error.msg);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      try {
        const deleteResp = Paths.EndpointsURL.DeleteNewsRoomBanner + `${id}`;
        const res = await axios.delete(deleteResp);
        handleDeleteResponse(res);
      } catch (error) {
        handleError(error);
      }
    } else {
      showMastermind();
    }
  };
  const confirmDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this data?',
      // icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    });
    return isConfirmed;
  };
  const handleDeleteResponse = (res) => {
    if (res.data.status !== 200 && res.data.success) {
      showMastermind();
      setIsDelete(res.data.data);
      toast.success(res.data.data, { position: 'top-right' });
    } else {
      toast.error(res.data.msg, { position: 'top-right' });
      console.log(res.data.data);
    }
  };
  const handleError = (error) => {
    console.error('Error deleting data:', error.message);
    toast.error('Error deleting data', { position: 'top-right' });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(mastermindDetails);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setMasterMindDetails(items);
  };

  useEffect(() => {
    showMastermind();
  }, []);

  return (
    <>
      <Container>
        <PageHeader
          title="News Room Banner"
          subTitle="News Room Banner"
          icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        />
        <Button variant="primary" onClick={() => setModalShow(true)}>
          Add New
        </Button>
        <NewsRoomBannerModel show={modalShow} onHide={() => setModalShow(false)} />
        <EditNewsRoomBannerModel
          show={modalShow1}
          onHide={() => setModalShow1(false)}
          editNewsRoomBannerData={selectedBanner}
        />
        <br />
        <br />
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Banner Name</th>
                <th scope="col">Banner Image</th>
                <th scope="col">Actions</th>
              </tr>
            </MDBTableHead>
            <Droppable droppableId="mastermindDetails">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {mastermindDetails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result, index) => (
                      <Draggable key={result._id} draggableId={result._id} index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>
                              <p className="fw-normal mb-1">{result.sequence}</p>
                            </td>
                            <td>
                              <p className="fw-normal mb-1">{result.bannerName}</p>
                            </td>

                            <td>
                              <img
                                src={result.bannerImage}
                                alt=""
                                style={{ width: '45px', height: '45px' }}
                                className="rounded-circle"
                              />
                            </td>
                            <td>
                              <Link
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleEdit(result)}
                              >
                                <EditIcon />
                              </Link>
                              <Link style={{ cursor: 'pointer' }}>
                                <VisibilityIcon />
                              </Link>
                              <Link
                                style={{ cursor: 'pointer', color: 'red' }}
                                onClick={() => handleDelete(result._id)}
                              >
                                <DeleteIcon />
                              </Link>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </MDBTable>
        </DragDropContext>
        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={mastermindDetails.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 15, 20]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        />
      </Container>
    </>
  );
};

export default NewsRoomBanner;
