import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import {
  styled,
  TablePagination,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import axios from 'axios';
import { Paths } from '../../../../app/baseApi/baseApi';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { format, subDays, startOfWeek, startOfMonth, startOfYear } from 'date-fns';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const RazorpayData = () => {
  const [offers_Form, setOffers_Form] = useState([]);
  const [isError, setIsError] = useState('');
  const [isDelete, setIsDelete] = useState('');
  const [filter, setFilter] = useState('Latest');
  const [filteredOffers, setFilteredOffers] = useState([]);

  const offersQueryForm = async () => {
    try {
      const response = await Paths.EndpointsURL.RazorpayPayment;
      axios({
        url: response,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }).then((record) => {
        setOffers_Form(record.data.data.reverse());
      });
    } catch (error) {
      setIsError(error.msg);
      console.log(error.msg);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      try {
        const deleteResp = Paths.EndpointsURL.DeleteRazorpayPayment + `${id}`;
        const res = await axios.delete(deleteResp);
        handleDeleteResponse(res);
      } catch (error) {
        handleError(error);
      }
    } else {
      offersQueryForm();
    }
  };

  const confirmDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this data?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    });
    return isConfirmed;
  };

  const handleDeleteResponse = (res) => {
    if (res.data.status !== 200 && res.data.success) {
      offersQueryForm();
      setIsDelete(res.data.data);
      toast.success(res.data.data, { position: 'top-right' });
    } else {
      toast.error(res.data.msg, { position: 'top-right' });
      console.log(res.data.data);
    }
  };

  const handleError = (error) => {
    console.error('Error deleting data:', error.message);
    toast.error('Error deleting data', { position: 'top-right' });
  };

  const filterOffers = (offers, filter) => {
    const now = new Date();
    switch (filter) {
      case 'Yesterday':
        return offers.filter(
          (offer) =>
            format(subDays(now, 1), 'yyyy-MM-dd') ===
            format(new Date(offer.createdAt), 'yyyy-MM-dd')
        );
      case 'Latest':
        return offers;
      case 'Week':
        return offers.filter((offer) => new Date(offer.createdAt) >= startOfWeek(now));
      case 'Month':
        return offers.filter((offer) => new Date(offer.createdAt) >= startOfMonth(now));
      case 'Year':
        return offers.filter((offer) => new Date(offer.createdAt) >= startOfYear(now));
      default:
        return offers;
    }
  };

  useEffect(() => {
    offersQueryForm();
  }, []);

  useEffect(() => {
    setFilteredOffers(filterOffers(offers_Form, filter));
  }, [offers_Form, filter]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadExcel = () => {
    const dataForExcel = filteredOffers.map((offer) => ({
      Name: offer.name,
      Email: offer.email,
      Phone: offer.number,
      PresentlyDoing: offer.presentlyDoing,
      CollegeName: offer.collegeName,
      State: offer.state,
      City: offer.city,
      'Created Date': format(new Date(offer.createdAt), 'yyyy-MM-dd'),
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Offers');
    XLSX.writeFile(workbook, 'RazorpaySignup.xlsx');
  };

  return (
    <Container>
      <PageHeader
        title="Razorpay Signup"
        subTitle="Razorpay Signup"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <FormControl variant="outlined" style={{ minWidth: 200, marginBottom: 20 }}>
        <InputLabel id="filter-label">Filter By</InputLabel>
        <Select
          labelId="filter-label"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label="Filter By"
        >
          <MenuItem value="Latest">All</MenuItem>
          <MenuItem value="Yesterday">Yesterday</MenuItem>
          <MenuItem value="Week">This Week</MenuItem>
          <MenuItem value="Month">This Month</MenuItem>
          <MenuItem value="Year">This Year</MenuItem>
        </Select>
      </FormControl>
      <Button onClick={downloadExcel}>Download</Button>
      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Presently Doing</th>
            <th scope="col">College Name</th>
            <th scope="col">State</th>
            <th scope="col">City</th>
            <th scope="col">Created Date</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        {filteredOffers.length === 0 ? (
          <MDBTableBody>
            <tr>
              <td colSpan="6">
                <Typography variant="h6" align="center">
                  No Record Available
                </Typography>
              </td>
            </tr>
          </MDBTableBody>
        ) : (
          filteredOffers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((result, index) => {
              return (
                <MDBTableBody key={index}>
                  <tr>
                    <td>
                      <p className="fw-normal mb-1">{result.name}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.email}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.number}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.presentlyDoing}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.collegeName}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.state}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.city}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">
                        {format(new Date(result.createdAt), 'yyyy-MM-dd')}
                      </p>
                    </td>
                    <td>
                      <Link
                        style={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => handleDelete(result._id)}
                      >
                        <DeleteIcon />
                      </Link>
                    </td>
                  </tr>
                </MDBTableBody>
              );
            })
        )}
      </MDBTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={filteredOffers.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default RazorpayData;
