import Modal from 'react-bootstrap/Modal';
import UploadPlanTHVideo from './UploadPlanTHVideo';

const PlanTHVideoModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanTHVideo />
      </Modal>
    </>
  );
};

export default PlanTHVideoModel;
