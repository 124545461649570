import Modal from 'react-bootstrap/Modal';
import UploadMastermindBanner from './UploadMastermindBanner';
const MastermindBannerModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadMastermindBanner />
      </Modal>
    </>
  );
};

export default MastermindBannerModel;
