import Modal from 'react-bootstrap/Modal';
import './Upload.css';
import UploadAllCourseBanner from './UploadAllCourseBanner';
const AllCourseBannerModal = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadAllCourseBanner />
      </Modal>
    </>
  );
};

export default AllCourseBannerModal;
