import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFile,
} from 'mdb-react-ui-kit';
import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const EditPlanMScPrice = ({ editPlanMScPriceData, onSubmit, onEdit }) => {
  const [editAllCoursesBanner, setEditAllCoursesBanner] = useState(editPlanMScPriceData || {});
  const [imagePreview, setImagePreview] = useState(editPlanMScPriceData?.planTabImage || '');

  useEffect(() => {
    setEditAllCoursesBanner(editPlanMScPriceData || {});
    setImagePreview(editPlanMScPriceData?.planTabImage || '');
  }, [editPlanMScPriceData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = files[0];
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setEditAllCoursesBanner({ ...editAllCoursesBanner, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editPlanMScPriceData) {
      onEdit(editAllCoursesBanner);
    } else {
      onSubmit(editAllCoursesBanner);
    }
  };

  const handleQuillChange = (value) => {
    setEditAllCoursesBanner((prevData) => ({ ...prevData, planDescription: value }));
  };
  // Function to strip HTML tags using DOMParser
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <Container>
        <MDBContainer fluid>
          <MDBRow
            className="d-flex justify-content-center align-items-center"
            style={{ width: '900px' }}
          >
            <MDBCol lg="6" className="my-1" style={{ width: '125%' }}>
              <h1 className="text-black mb-4 text-center">Edit Plan MSc Price</h1>
              <form onSubmit={handleSubmit}>
                <MDBCard>
                  <MDBCardBody className="px-4">
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Tab Heading"
                          size="lg"
                          name="planTabHeading"
                          value={editAllCoursesBanner.planTabHeading || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Tab SubHeading"
                          size="lg"
                          name="planTabSubHeading"
                          value={editAllCoursesBanner.planTabSubHeading || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Tab Paragraph"
                          size="lg"
                          name="planTabParagraph"
                          value={editAllCoursesBanner.planTabParagraph || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan Name"
                          size="lg"
                          name="planName"
                          value={editAllCoursesBanner.planName || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan Validity"
                          size="lg"
                          name="planValidity"
                          value={editAllCoursesBanner.planValidity || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan ActualPrice"
                          size="lg"
                          name="planActualPrice"
                          value={editAllCoursesBanner.planActualPrice || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Plan CutPrice"
                          size="lg"
                          name="planCutPrice"
                          value={editAllCoursesBanner.planCutPrice || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="With Book"
                          size="lg"
                          name="withBook"
                          value={editAllCoursesBanner.withBook || ''}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <ReactQuill
                          type="text"
                          name="planDescription"
                          value={editAllCoursesBanner.planDescription}
                          onChange={handleQuillChange}
                          modules={module}
                          theme="snow"
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBFile
                          name="planTabImage"
                          type="file"
                          size="lg"
                          accept="image/*"
                          onChange={handleInputChange}
                        />
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Image Preview"
                            style={{ width: '100%', height: 'auto', marginTop: '10px' }}
                          />
                        )}
                        <div className="small text-muted mt-2">
                          Upload your file or any other relevant file. Max file size 50 MB
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBBtn className="my-4" size="lg" type="submit">
                      Update
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Container>
    </>
  );
};

export default EditPlanMScPrice;
