import Modal from 'react-bootstrap/Modal';
import UploadPlanZeroBenefits from './UploadPlanZeroBenefits';
const PlanZeroBenefitsModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanZeroBenefits />
      </Modal>
    </>
  );
};

export default PlanZeroBenefitsModel;
