import Modal from 'react-bootstrap/Modal';
import UploadPlanZeroSalient from './UploadPlanZeroSalient.';
const PlanZeroSalientModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanZeroSalient />
      </Modal>
    </>
  );
};

export default PlanZeroSalientModel;
