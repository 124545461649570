import Modal from 'react-bootstrap/Modal';
import UploadPlanZeroPrice from './UploadPlanZeroPrice';
const PlanZeroPriceModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton style={{ width: '990px' }}></Modal.Header>
        <UploadPlanZeroPrice />
      </Modal>
    </>
  );
};

export default PlanZeroPriceModel;
