import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Link from '@mui/material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import Button from 'react-bootstrap/Button';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Paths } from '../../../../app/baseApi/baseApi';
import './Upload.css';
import EditShowFaqModel from './EditShowFaqModel';
import UploadFaqModal from './UploadFaqModal';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const HomeFAQ = () => {
  const [homeExams, setHomeExams] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isError, setIsError] = useState('');
  const [isDelete, setIsDelete] = useState('');
  const [isExpanded, setIsExpanded] = useState([]);

  const handleEdit = (id) => {
    setSelectedBanner(id);
    setModalShow1(true);
  };

  const displayExams = async () => {
    try {
      const response = await Paths.EndpointsURL.GetHomePageFAQ;
      axios({
        url: response,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }).then((record) => {
        setHomeExams(record.data.data);
        // console.log(record.data.data);
      });
    } catch (error) {
      setIsError(error.msg);
      console.log(error.msg);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      try {
        const deleteResp = Paths.EndpointsURL.DeleteHomePageFAQ + `${id}`;
        const res = await axios.delete(deleteResp);
        handleDeleteResponse(res);
      } catch (error) {
        handleError(error);
      }
    } else {
      displayExams();
    }
  };
  const confirmDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this data?',
      // icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    });
    return isConfirmed;
  };
  const handleDeleteResponse = (res) => {
    if (res.data.status !== 200 && res.data.success) {
      displayExams();
      setIsDelete(res.data.data);
      toast.success(res.data.data, { position: 'top-right' });
    } else {
      toast.error(res.data.msg, { position: 'top-right' });
      console.log(res.data.data);
    }
  };
  const handleError = (error) => {
    console.error('Error deleting data:', error.message);
    toast.error('Error deleting data', { position: 'top-right' });
  };

  useEffect(() => {
    displayExams();
  }, []);

  const handleToggleClick = (index) => {
    setIsExpanded((prevExpandedItems) => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleOpen = () => {
  //   setModalShow(true);
  // };
  return (
    <Container>
      <PageHeader
        title="Home FAQ"
        subTitle="Manage Home FAQ"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New
      </Button>
      <UploadFaqModal show={modalShow} onHide={() => setModalShow(false)} />
      <EditShowFaqModel
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        editAllFAQData={selectedBanner}
      />
      <MDBTable align="middle">
        <MDBTableHead>
          <tr>
            <th scope="col" style={{ padding: '15px 0px 15px 25px' }}>
              Faq Heading
            </th>
            <th scope="col" style={{ padding: '15px 0px 15px 25px' }}>
              Faq TabName
            </th>
            <th scope="col">Description</th>
            <th scope="col">Actions</th>
          </tr>
        </MDBTableHead>
        {homeExams
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((result, index) => {
            return (
              <>
                <MDBTableBody>
                  <tr key={index}>
                    <td>
                      <p className="fw-normal mb-1">{result.faqTabName}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{result.faqHeading}</p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        dangerouslySetInnerHTML={{
                          __html: isExpanded[index]
                            ? result.faqDescription
                            : `${result.faqDescription.substring(0, 100)}...`,
                        }}
                      ></p>
                    </td>

                    <td>
                      <Link
                        style={{ padding: '0px', cursor: 'pointer' }}
                        onClick={() => handleEdit(result)}
                      >
                        <EditIcon />
                      </Link>
                      <Link>
                        <VisibilityIcon />
                      </Link>

                      <Link
                        onClick={() => handleDelete(result._id)}
                        style={{ color: 'red', cursor: 'pointer' }}
                      >
                        <DeleteIcon />
                      </Link>
                    </td>
                  </tr>
                </MDBTableBody>
              </>
            );
          })}
      </MDBTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={homeExams.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Container>
  );
};

export default HomeFAQ;
