import './ViewPlanCaters.css';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';
const ViewPlanCaters = ({ isOpen, onClose, result }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`nextian_modal ${isOpen ? 'show' : ''}`} onClick={onClose}>
      <div className="nextian_modal_content" onClick={handleModalClick}>
        <div className="nextian_modal_header">
          <span className="nextian_modal_close" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="card_header">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{result?.catersHeading}</MDBCardTitle>

              <div className="scroll_paragraph_1">
                <MDBCardText
                  dangerouslySetInnerHTML={{ __html: result?.catersParagraph }}
                ></MDBCardText>
              </div>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
};

export default ViewPlanCaters;
