import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export default function EditCouponCode({ editCouponData, onEdit }) {
  const [editCouponCode, setEditCouponCode] = useState(editCouponData || {});
  const [changedFields, setChangedFields] = useState({});

  useEffect(() => {
    setEditCouponCode(editCouponData || {});
    setChangedFields({});
  }, [editCouponData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditCouponCode((prevData) => ({ ...prevData, [name]: value }));
    if (value !== editCouponData[name]) {
      setChangedFields((prevFields) => ({ ...prevFields, [name]: value }));
    } else {
      setChangedFields((prevFields) => {
        const newFields = { ...prevFields };
        delete newFields[name];
        return newFields;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onEdit({ ...editCouponData, ...changedFields });
  };
  return (
    <Container>
      <MDBContainer fluid>
        <MDBRow
          className="d-flex justify-content-center align-items-center"
          style={{ width: '900px' }}
        >
          <MDBCol lg="12" className="my-2">
            <h3 className="text-black mt-0 text-center">Edit Coupon</h3>
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  {/* Plan Name and Validity */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Name"
                        name="planName"
                        id="planName"
                        onChange={handleChange}
                        value={editCouponCode.planName || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>

                    <MDBCol md="6">
                      <MDBInput
                        label="Plan Validity"
                        name="planValidity"
                        id="planValidity"
                        onChange={handleChange}
                        value={editCouponCode.planValidity || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Coupon Code and Amount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Coupon Code"
                        name="couponCode"
                        id="couponCode"
                        onChange={handleChange}
                        value={editCouponCode.couponCode || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Coupon Amount"
                        name="couponAmount"
                        id="couponAmount"
                        onChange={handleChange}
                        value={editCouponCode.couponAmount || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Minimum Purchase"
                        name="minimumPurchase"
                        id="minimumPurchase"
                        onChange={handleChange}
                        value={editCouponCode.minimumPurchase || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Maximum Discount"
                        name="maximumDiscount"
                        id="maximumDiscount"
                        onChange={handleChange}
                        value={editCouponCode.maximumDiscount || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n2 m-0" />
                  {/* Sales Person and Maximum Discount */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Sales Person"
                        name="salesPerson"
                        id="salesPerson"
                        onChange={handleChange}
                        value={editCouponCode.salesPerson || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="Total Coupon"
                        name="bulkCoupon"
                        id="bulkCoupon"
                        onChange={handleChange}
                        value={editCouponCode.bulkCoupon || ''}
                        size="lg"
                        type="text"
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <select
                        className="form-control"
                        id="couponType"
                        name="couponType"
                        onChange={handleChange}
                        value={editCouponCode.couponType || ''}
                        size="lg"
                      >
                        <option value="">Select Coupon Type</option>
                        <option value="Sales">Sales</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Video">Video</option>
                      </select>
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n2 m-0" />
                  {/* Start Date and End Date */}
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="6">
                      <MDBInput
                        label="Start Date"
                        id="startDate"
                        name="startDate"
                        onChange={handleChange}
                        value={editCouponCode.startDate || ''}
                        size="lg"
                        type="date"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        label="End Date"
                        id="endDate"
                        name="endDate"
                        onChange={handleChange}
                        value={editCouponCode.endDate || ''}
                        size="lg"
                        type="date"
                      />
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n2 m-0" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Update
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
}
