import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFile,
  MDBTextArea,
} from 'mdb-react-ui-kit';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const UploadPlanCaters = () => {
  const [uploadFAQ, setUploadFAQ] = useState({
    catersHeading: '',
    catersParagraph: '',
  });
  const [submittedData, setSubmittedData] = useState(null);
  const [isError, setIsError] = useState([]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUploadFAQ({ ...uploadFAQ, [name]: value });
    setIsError({ ...isError, [name]: value });
  };

  const handleQuillChange = (value) => {
    setUploadFAQ({ ...uploadFAQ, catersParagraph: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!uploadFAQ.catersHeading.trim()) {
      newErrors.catersHeading = 'Heading is required';
      isValid = false;
    }
    setIsError(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newOffers = { ...uploadFAQ, id: new Date() };
    if (validateForm()) {
      try {
        const response = await Paths.EndpointsURL.PostPlanUGCaters;
        const res = await axios({
          url: response,
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          data: JSON.stringify(newOffers),
        });
        setUploadFAQ(res.data.data);
        window.location.reload();
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        setUploadFAQ({
          catersHeading: '',
          catersParagraph: '',
        });
      } catch (error) {
        toast.error('Error uploading data65:', error);
      }
    }
  };

  // Function to strip HTML tags using DOMParser
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <Container>
        <MDBContainer fluid className="d-flex justify-content-center align-items-center">
          <MDBRow className="d-flex justify-content-center align-items-center">
            <MDBCol lg="8" className="my-1" style={{ width: '900px' }}>
              <h1 className="text-black mb-4 text-center">Upload Caters</h1>
              <form onSubmit={handleSubmit} style={{ width: '900px' }}>
                <MDBCard>
                  <MDBCardBody className="px-4">
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>
                      <MDBCol md="9" className="pe-5">
                        <MDBInput
                          label="Caters Heading"
                          size="lg"
                          id="catersHeading"
                          name="catersHeading"
                          value={uploadFAQ.catersHeading}
                          type="text"
                          onChange={handleChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <hr className="mx-n3" />
                    <MDBRow className="align-items-center pt-4 pb-3">
                      <MDBCol md="3" className="ps-5"></MDBCol>

                      <MDBCol md="9" className="pe-5">
                        <ReactQuill
                          type=""
                          id="catersParagraph"
                          name="catersParagraph"
                          value={uploadFAQ.catersParagraph}
                          onChange={handleQuillChange}
                          modules={module}
                        />
                      </MDBCol>
                    </MDBRow>
                    <hr className="mx-n3" />
                    <MDBBtn className="my-4" size="lg" type="submit">
                      Submit
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Container>
    </>
  );
};

export default UploadPlanCaters;
