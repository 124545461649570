import Modal from 'react-bootstrap/Modal';
import UploadPlanABenefits from './UploadPlanABenefits';

const PlanABenefitsModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanABenefits />
      </Modal>
    </>
  );
};

export default PlanABenefitsModel;
