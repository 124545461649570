import Modal from 'react-bootstrap/Modal';
import UploadPlanMLBLiteSalient from './UploadPlanMLBLiteSalient';

const PlanMLBLiteSalientModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadPlanMLBLiteSalient />
      </Modal>
    </>
  );
};

export default PlanMLBLiteSalientModel;
