import Modal from 'react-bootstrap/Modal';
import GenerateCoupon from './GenerateCoupon';
const GenerateCouponModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <GenerateCoupon />
      </Modal>
    </>
  );
};

export default GenerateCouponModel;
