import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import DisplayNextians from './ProudNextians/DisplayNextians';
import DisplayPlanA from '../Courses/PlanA/DisplayPlanA';
import DisplayPlanB from '../Courses/PlanB/DisplayPlanB';
import DisplayPlanC from '../Courses/PlanC/DisplayPlanC';
import DisplayPlanZero from '../Courses/PlanZero/DisplayPlanZero';
import DisplayPlanNP from '../Courses/PlanNP/DisplayPlanNP';
import DisplayPlanUG from '../Courses/PlanUG/DisplayPlanUG';
import DisplayPlanMSC from '../Courses/PlanMSC/DisplayPlanMSC';
import DisplayPlanRRR from '../Courses/PlanRRR/DisplayPlanRRR';
import DisplayPlanTH from '../Courses/PlanTH/DisplayPlanTH';
import ShowExams from './Exams/ShowExams';
import ShowFaq from './AllFAQ/ShowFaq';
import HomeFeedback from './HomeFeedback/HomeFeedback';
import CoursesForm from './CoursesForm/CoursesForm';
import AboutFeedback from './AboutFeedback/AboutFeedback';
import HomeBanner from './HomeBanner/HomeBanner';
import AboutBanner from './AboutBanner/AboutBanner';
import OffersBanner from './OffersBanner/OffersBanner';
import AllCourseBanner from './AllCourseBanner/AllCourseBanner';
import HomeCourses from './HomeCourses/HomeCourses';
import WhyChoose from './WhyChoose/WhyChoose';
import PlanZeroPrice from '../Plans/PlanZero/PlanZeroPrice';
import PlanZeroBanner from '../Plans/PlanZeroBanner/PlanZeroBanner';
import PlanZeroBenefits from '../Plans/PlanZeroBenefits/PlanZeroBenefits';
import PlanZeroSalient from '../Plans/PlanZeroSalient/PlanZeroSalient';
import PlanZeroVideo from '../Plans/PlanZeroVideo/PlanZeroVideo';
import MLBPROVideo from '../Plans/MLBPRO/MLBPROVideo/MLBPROVideo';
import AllExamsBanner from './AllExamsBanner/AllExamsBanner';
import SuccessStoriesBanner from './SuccessStoriesBanner/SuccessStoriesBanner';
import NewsRoomBanner from './NewsRoomBanner/NewsRoomBanner';
import BlogsBanner from './BlogsBanner/BlogsBanner';
import MastermindBanner from './MastermindBanner/MastermindBanner';
import ProudNextiansBanner from './ProudNextiansBanner/ProudNextiansBanner';
import NORCETBanner from './NORCETBanner/NORCETBanner';
import NursingOfficerBanner from './NursingOfficerBanner/NursingOfficerBanner';
import ExamMscBanner from './ExamMscBanner/ExamMscBanner';
import NclexBanner from './NclexBanner/NclexBanner';
import CHOBanner from './CHOBanner/CHOBanner';
import MLBProBanner from '../Plans/MLBProBanner/MLBProBanner';
import CPlusBanner from '../Plans/CPlusBanner/CPlusBanner';
import PlanZeroForm from './PlanZeroForm/PlanZeroForm';
import PlanMLBProForm from './PlanMLBProForm/PlanMLBProForm';
import PlanCPlusForm from './PlanCPlusForm/PlanCPlusForm';
import PlanUGForm from './PlanUGForm/PlanUGForm';
import PlanMLBLiteForm from './PlanMLBLiteForm/PlanMLBLiteForm';
import PlanAForm from './PlanAForm/PlanAForm';
import PlanMScForm from './PlanMScForm/PlanMScForm';
import PlanNNLForm from './PlanNNLForm/PlanNNLForm';
import PlanTHForm from './PlanTHForm/PlanTHForm';
import CPlusVideo from '../Plans/CPlusVideo/CPlusVideo';
import PlanUGVideo from '../Plans/PlanUGVideo/PlanUGVideo';
import PlanMLBLiteVideo from '../Plans/PlanMLBLiteVideo/PlanMLBLiteVideo';
import PlanAVideo from '../Plans/PlanAVideo/PlanAVideo';
import PlanMScVideo from '../Plans/PlanMScVideo/PlanMScVideo';
import PlanNNLVideo from '../Plans/PlanNNLVideo/PlanNNLVideo';
import PlanTHVideo from '../Plans/PlanTHVideo/PlanTHVideo';
import PlanMLBProBenefits from '../Plans/PlanMLBProBenefits/PlanMLBProBenefits';
import CPlusBenefits from '../Plans/CPlusBenefits/CPlusBenefits';
import PlanUGBenefits from '../Plans/PlanUGBenefits/PlanUGBenefits';
import PlanMLBLiteBenefits from '../Plans/PlanMLBLiteBenefits/PlanMLBLiteBenefits';
import PlanABenefits from '../Plans/PlanABenefits/PlanABenefits';
import PlanMScBenefits from '../Plans/PlanMScBenefits/PlanMScBenefits';
import PlanNNLBenefits from '../Plans/PlanNNLBenefits/PlanNNLBenefits';
import PlanTHBenefits from '../Plans/PlanTHBenefits/PlanTHBenefits';
import PlanMLBProSalient from '../Plans/PlanMLBProSalient/PlanMLBProSalient';
import CPlusSalient from '../Plans/CPlusSalient/CPlusSalient';
import PlanUGSalient from '../Plans/PlanUGSalient/PlanUGSalient';
import PlanMLBLiteSalient from '../Plans/PlanMLBLiteSalient/PlanMLBLiteSalient';
import PlanASalient from '../Plans/PlanASalient/PlanASalient';
import PlanMScSalient from '../Plans/PlanMScSalient/PlanMScSalient';
import PlanNNLSalient from '../Plans/PlanNNLSalient/PlanNNLSalient';
import PlanTHSalient from '../Plans/PlanTHSalient/PlanTHSalient';
import PlanMLBProPrice from '../Plans/PlanMLBProPrice/PlanMLBProPrice';
import CPlusPrice from '../Plans/CPlusPrice/CPlusPrice.';
import PlanUGPrice from '../Plans/PlanUGPrice/PlanUGPrice';
import PlanMLBLitePrice from '../Plans/PlanMLBLitePrice/PlanMLBLitePrice';
import PlanMScPrice from '../Plans/PlanMScPrice/PlanMScPrice';
import PlanNNLPrice from '../Plans/PlanNNLPrice/PlanNNLPrice';
import PlanTHPrice from '../Plans/PlanTHPrice/PlanTHPrice';
import PlanAPrice from '../Plans/PlanAPrice/PlanAPrice';
import Offers from './Offers/Offers';
import PlanUGBanner from '../Plans/PlanUGBanner/PlanUGBanner';
import MLBLiteBanner from '../Plans/MLBLiteBanner/MLBLiteBanner';
import PlanABanner from '../Plans/PlanABanner/PlanABanner';
import PlanMScBanner from '../Plans/PlanMScBanner/PlanMScBanner';
import PlanNNLBanner from '../Plans/PlanNNLBanner/PlanNNLBanner';
import PlanTHBanner from '../Plans/PlanTHBanner/PlanTHBanner';
import RazorpayData from './RazorpayData/RazorpayData';
import ContactusForm from './ContactusForm/ContactusForm';
import HiringForm from './HiringForm/HiringForm';
import HelpSupportForm from './HelpSupportForm/HelpSupportForm';
import CareerPage from './CareerPage/CareerPage';
import HomeFAQ from './HomeFAQ/HomeFAQ';
import PlanCaters from './PlanCaters/PlanCaters';
import CouponCode from './CouponCode/CouponCode';
import VacancyVideo from './VacancyVideo/VacancyVideo';

const AppTable = Loadable(lazy(() => import('./WhyChoose/WhyChoose')));
const DisplayMaster = Loadable(lazy(() => import('./MasterMindDetails/DisplayMaster')));
const DisplaySuccessStories = Loadable(
  lazy(() => import('./SuccessStories/DisplaySuccessStories'))
);
const DisplayBlog = Loadable(lazy(() => import('./Blog/DisplayBlog')));

const DisplayOffersQueryForm = Loadable(
  lazy(() => import('./OffersQueryForm/DisplayOffersQueryForm'))
);
const DisplayVideos = Loadable(lazy(() => import('./VideoSnippts/DisplayVideos')));
const AppSnackbar = Loadable(lazy(() => import('./snackbar/AppSnackbar')));
const QueryForm = Loadable(lazy(() => import('./QueryForm/QueryForm')));

const materialRoutes = [
  {
    path: '/faculty',
    element: <DisplayMaster />,
  },
  {
    path: '/vacancy-video',
    element: <VacancyVideo />,
  },
  {
    path: '/coupon-code',
    element: <CouponCode />,
  },
  {
    path: '/home/feedback',
    element: <HomeFeedback />,
  },

  {
    path: '/course/form',
    element: <CoursesForm />,
  },

  {
    path: '/contactus/form',
    element: <ContactusForm />,
  },

  {
    path: '/hiring/form',
    element: <HiringForm />,
  },
  {
    path: '/career',
    element: <CareerPage />,
  },
  {
    path: '/home/faq',
    element: <HomeFAQ />,
  },
  {
    path: '/help/support',
    element: <HelpSupportForm />,
  },
  {
    path: '/offers/form',
    element: <DisplayOffersQueryForm />,
  },
  {
    path: '/plan-caters',
    element: <PlanCaters />,
  },
  {
    path: '/about/feedback',
    element: <AboutFeedback />,
  },
  {
    path: '/about/banner/',
    element: <AboutBanner />,
  },

  {
    path: '/offers/banner',
    element: <OffersBanner />,
  },
  {
    path: '/offers',
    element: <Offers />,
  },
  {
    path: '/all/course/banner',
    element: <AllCourseBanner />,
  },

  {
    path: '/home/course',
    element: <HomeCourses />,
  },

  {
    path: '/home/banner',
    element: <HomeBanner />,
  },
  {
    path: '/plan-ug/banner',
    element: <PlanUGBanner />,
  },
  {
    path: '/mlb-lite/banner',
    element: <MLBLiteBanner />,
  },
  {
    path: '/plan-a/banner',
    element: <PlanABanner />,
  },
  {
    path: '/plan-msc/banner',
    element: <PlanMScBanner />,
  },
  {
    path: '/nnl-vsl/banner',
    element: <PlanNNLBanner />,
  },
  {
    path: '/plan-th/banner',
    element: <PlanTHBanner />,
  },
  {
    path: '/razorpay/payment',
    element: <RazorpayData />,
  },
  {
    path: '/success/stories',
    element: <DisplaySuccessStories />,
  },

  {
    path: '/display/blog',
    element: <DisplayBlog />,
  },
  {
    path: '/offers/query',
    element: <DisplayOffersQueryForm />,
  },
  {
    path: '/video/snippts',
    element: <DisplayVideos />,
  },
  {
    path: '/all/faq',
    element: <ShowFaq />,
  },
  {
    path: '/query/form',
    element: <QueryForm />,
  },

  {
    path: '/display/nextians',
    element: <DisplayNextians />,
  },

  {
    path: '/all/exams',
    element: <ShowExams />,
  },

  {
    path: '/why/choose',
    element: <WhyChoose />,
  },

  // Plans Start here
  {
    path: '/plan-zero/form',
    element: <PlanZeroForm />,
  },
  {
    path: '/mlb-pro/form',
    element: <PlanMLBProForm />,
  },
  {
    path: '/c-plus/form',
    element: <PlanCPlusForm />,
  },
  {
    path: '/plan-ug/form',
    element: <PlanUGForm />,
  },
  {
    path: '/plan-a/form',
    element: <PlanAForm />,
  },
  {
    path: '/plan-msc/form',
    element: <PlanMScForm />,
  },
  {
    path: '/plan-nnl/form',
    element: <PlanNNLForm />,
  },
  {
    path: '/plan-th/form',
    element: <PlanTHForm />,
  },
  {
    path: '/mlb-lite/form',
    element: <PlanMLBLiteForm />,
  },
  {
    path: '/price',
    element: <PlanZeroPrice />,
  },
  {
    path: '/plan-zero/banner',
    element: <PlanZeroBanner />,
  },

  {
    path: '/key-benefits',
    element: <PlanZeroBenefits />,
  },
  {
    path: '/plan-zero/salient',
    element: <PlanZeroSalient />,
  },
  {
    path: '/plan-zero/video',
    element: <PlanZeroVideo />,
  },

  {
    path: '/mlb-pro/video',
    element: <MLBPROVideo />,
  },
  {
    path: '/c-plus/video',
    element: <CPlusVideo />,
  },
  {
    path: '/plan-ug/video',
    element: <PlanUGVideo />,
  },
  {
    path: '/mlb-lite/video',
    element: <PlanMLBLiteVideo />,
  },
  {
    path: '/plan-a/video',
    element: <PlanAVideo />,
  },
  {
    path: '/plan-msc/video',
    element: <PlanMScVideo />,
  },
  {
    path: '/plan-nnl/video',
    element: <PlanNNLVideo />,
  },
  {
    path: '/plan-th/video',
    element: <PlanTHVideo />,
  },
  {
    path: '/exams/banner',
    element: <AllExamsBanner />,
  },
  {
    path: '/stories/banner',
    element: <SuccessStoriesBanner />,
  },
  {
    path: '/newsroom/banner',
    element: <NewsRoomBanner />,
  },
  {
    path: '/blogs/banner',
    element: <BlogsBanner />,
  },
  {
    path: '/faculty/banner',
    element: <MastermindBanner />,
  },
  {
    path: '/nextians/banner',
    element: <ProudNextiansBanner />,
  },
  {
    path: '/norcet/banner',
    element: <NORCETBanner />,
  },
  {
    path: '/nursing-officer/banner',
    element: <NursingOfficerBanner />,
  },
  {
    path: '/exam-msc/banner/',
    element: <ExamMscBanner />,
  },
  {
    path: '/exam-nclex/banner/',
    element: <NclexBanner />,
  },
  {
    path: '/cho/banner/',
    element: <CHOBanner />,
  },
  {
    path: '/mlb-pro/banner/',
    element: <MLBProBanner />,
  },
  {
    path: '/c-plus/banner/',
    element: <CPlusBanner />,
  },
  {
    path: '/mlb-pro/benefits/',
    element: <PlanMLBProBenefits />,
  },
  {
    path: '/c-plus/benefits/',
    element: <CPlusBenefits />,
  },
  {
    path: '/plan-ug/benefits/',
    element: <PlanUGBenefits />,
  },
  {
    path: '/mlb-lite/benefits/',
    element: <PlanMLBLiteBenefits />,
  },
  {
    path: '/plan-a/benefits/',
    element: <PlanABenefits />,
  },
  {
    path: '/plan-msc/benefits/',
    element: <PlanMScBenefits />,
  },
  {
    path: '/nnl-vsl/benefits/',
    element: <PlanNNLBenefits />,
  },
  {
    path: '/plan-th/benefits/',
    element: <PlanTHBenefits />,
  },
  {
    path: '/mlb-pro/salient/',
    element: <PlanMLBProSalient />,
  },
  {
    path: '/c-plus/salient/',
    element: <CPlusSalient />,
  },
  {
    path: '/plan-ug/salient/',
    element: <PlanUGSalient />,
  },
  {
    path: '/mlb-lite/salient/',
    element: <PlanMLBLiteSalient />,
  },
  {
    path: '/plan-a/salient/',
    element: <PlanASalient />,
  },
  {
    path: '/plan-msc/salient/',
    element: <PlanMScSalient />,
  },
  {
    path: '/nnl-vsl/salient/',
    element: <PlanNNLSalient />,
  },
  {
    path: '/plan-th/salient/',
    element: <PlanTHSalient />,
  },
  {
    path: '/mlb-pro/price/',
    element: <PlanMLBProPrice />,
  },
  {
    path: '/c-plus/price/',
    element: <CPlusPrice />,
  },
  {
    path: '/plan-ug/price/',
    element: <PlanUGPrice />,
  },
  {
    path: '/mlb-lite/price/',
    element: <PlanMLBLitePrice />,
  },
  {
    path: '/plan-msc/price/',
    element: <PlanMScPrice />,
  },
  {
    path: '/nnl-vsl/price/',
    element: <PlanNNLPrice />,
  },
  {
    path: '/plan-a/price/',
    element: <PlanAPrice />,
  },
  {
    path: '/plan-th/price/',
    element: <PlanTHPrice />,
  },
  // Plans End here
  // Courses Route Start here
  {
    path: '/courses/list',
    element: <DisplayPlanA />,
  },
  {
    path: '/courses',
    element: <DisplayPlanB />,
  },
  {
    path: '/courses/plan-c',
    element: <DisplayPlanC />,
  },
  {
    path: '/courses/plan-zero',
    element: <DisplayPlanZero />,
  },
  {
    path: '/courses/plan-np',
    element: <DisplayPlanNP />,
  },
  {
    path: '/courses/plan-ug',
    element: <DisplayPlanUG />,
  },
  {
    path: '/courses/plan-msc',
    element: <DisplayPlanMSC />,
  },
  {
    path: '/courses/plan-rrr',
    element: <DisplayPlanRRR />,
  },
  {
    path: '/courses/plan-th',
    element: <DisplayPlanTH />,
  },
];

export default materialRoutes;
