import Modal from 'react-bootstrap/Modal';
import EditShowFaq from './EditShowFaq';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Paths } from '../../../../app/baseApi/baseApi';

const EditShowFaqModel = ({ editAllFAQData, show, onHide }) => {
  const handleEditSubmit = async (editedData) => {
    try {
      const response = await axios.put(
        Paths.EndpointsURL.UpdateHomePageFAQ + editedData._id,
        editedData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      toast.success('Record Updated Successfully', { position: 'top-right' });
      window.location.reload();
      onHide();
    } catch (error) {
      console.error('Error updating FAQ:', error);
      toast.error('Error updating FAQ: ' + error.message);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <EditShowFaq editAllFAQData={editAllFAQData} onEdit={handleEditSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default EditShowFaqModel;
