import React from 'react';
import * as XLSX from 'xlsx';

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBTableBody,
  MDBTable,
} from 'mdb-react-ui-kit';
import { Button } from 'react-bootstrap';

export default function ViewCouponCode({ basicModal, toggleOpen, coupon }) {
  const handleDownload = () => {
    if (coupon.usedBy && coupon.usedBy.length > 0) {
      const worksheetData = coupon.usedBy.map((user) => ({
        Name: user.username,
        Number: user.mobileNumber,
      }));
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'CouponUsed');
      XLSX.writeFile(workbook, 'CouponUsed.xlsx');
    } else {
      alert('No coupon data to download');
    }
  };
  return (
    <>
      <MDBModal show={basicModal} getOpenState={toggleOpen} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent className="bg-white shadow-1-strong" style={{ width: '160%' }}>
            <MDBModalHeader>
              <MDBModalTitle>Coupon Details</MDBModalTitle>&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="primary" onClick={handleDownload}>
                Download
              </Button>
              <MDBBtn className="btn-close" color="none" onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBTable align="middle">
                {coupon ? (
                  <MDBTableBody>
                    <tr>
                      <td>
                        <strong>Plan Name</strong>
                      </td>
                      <td>{coupon.planName}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Plan Validity</strong>
                      </td>
                      <td>{coupon.planValidity}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Coupon Code</strong>
                      </td>
                      <td>{coupon.couponCode}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Discount Amount</strong>
                      </td>
                      <td>{coupon.couponAmount}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Expire Date</strong>
                      </td>
                      <td> {new Date(coupon.endDate).toLocaleDateString('en-CA')} </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Coupon Type</strong>
                      </td>
                      <td> {coupon.couponType} </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Used By</strong>
                      </td>
                      <td>
                        {coupon.usedBy && coupon.usedBy.length > 0 ? (
                          <div>
                            {coupon.usedBy.map((user, idx) => (
                              <div key={idx}>
                                <td>
                                  <strong>Name: {user.username}</strong> | Number:{' '}
                                  {user.mobileNumber}
                                </td>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>No Coupon Used</p>
                        )}
                      </td>
                    </tr>
                  </MDBTableBody>
                ) : (
                  <p>No coupon data available</p>
                )}
              </MDBTable>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
