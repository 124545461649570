import { styled } from '@mui/material';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from 'mdb-react-ui-kit';
import './Upload.css';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Paths } from '../../../../app/baseApi/baseApi';
import axios from 'axios';
import ReactQuill from 'react-quill';

const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

const UploadFAQ = () => {
  const [uploadFAQ, setUploadFAQ] = useState({
    faqHeading: '',
    faqDescription: '',
    faqTabName: '',
  });
  const [isError, setIsError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUploadFAQ((prev) => ({ ...prev, [name]: value }));
    setIsError((prev) => ({ ...prev, [name]: '' }));
  };

  const handleQuillChange = (value) => {
    setUploadFAQ((prev) => ({ ...prev, faqDescription: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!uploadFAQ.faqHeading.trim()) newErrors.faqHeading = 'Heading is required';
    setIsError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(Paths.EndpointsURL.PostHomePageFAQ, uploadFAQ, {
          headers: { 'Content-Type': 'application/json' },
        });
        toast.success(response.data.message, { position: 'top-right', autoClose: 2000 });
        window.location.reload();
        setUploadFAQ({
          faqHeading: '',
          faqDescription: '',
          faqTabName: '',
        });
      } catch (error) {
        toast.error(`Error uploading data: ${error.response?.data?.message || error.message}`);
      }
    }
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <Container>
      <MDBContainer fluid className="d-flex justify-content-center align-items-center">
        <MDBRow className="d-flex justify-content-center align-items-center">
          <MDBCol lg="8" className="my-1" style={{ width: '900px' }}>
            <h1 className="text-black mb-4 text-center">Upload FAQ</h1>
            <form onSubmit={handleSubmit} style={{ width: '900px' }}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <hr className="mx-n3" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5"></MDBCol>
                    <MDBCol md="9" className="pe-5">
                      <MDBInput
                        label="Tab Name"
                        size="lg"
                        id="faqTabName"
                        name="faqTabName"
                        value={uploadFAQ.faqTabName}
                        type="text"
                        onChange={handleChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n3" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5"></MDBCol>
                    <MDBCol md="9" className="pe-5">
                      <MDBInput
                        label="FAQ Heading"
                        size="lg"
                        id="faqHeading"
                        name="faqHeading"
                        value={uploadFAQ.faqHeading}
                        type="text"
                        onChange={handleChange}
                        required
                      />
                      {isError.faqHeading && (
                        <div className="text-danger">{isError.faqHeading}</div>
                      )}
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n3" />
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5"></MDBCol>
                    <MDBCol md="9" className="pe-5">
                      <ReactQuill
                        id="faqDescription"
                        value={uploadFAQ.faqDescription}
                        onChange={handleQuillChange}
                        modules={module}
                      />
                    </MDBCol>
                  </MDBRow>
                  <hr className="mx-n3" />
                  <MDBBtn className="my-4" size="lg" type="submit">
                    Submit
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Container>
  );
};

export default UploadFAQ;
