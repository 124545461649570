import Modal from 'react-bootstrap/Modal';
import './Upload.css';
import UploadOffersBanner from './UploadOffersBanner';
const OffersBannerModal = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <UploadOffersBanner />
      </Modal>
    </>
  );
};

export default OffersBannerModal;
