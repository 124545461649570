import Modal from 'react-bootstrap/Modal';
import UploadPlanMLBProPrice from './UploadPlanMLBProPrice';
const PlanMLBProPriceModel = (props) => {
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton style={{ width: '990px' }}></Modal.Header>
        <UploadPlanMLBProPrice />
      </Modal>
    </>
  );
};

export default PlanMLBProPriceModel;
